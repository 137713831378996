.bg-pattern {
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.pattern-1 {
    &:before {
      background: url(../image/patterns/pattern-1.png) no-repeat center;
      background-size: cover;
      opacity: 0.14;

    }
  }
  &.pattern-2 {
    &:before {
      // content: url(../image/patterns/pattern-2.png);
      background: url(../image/patterns/pattern-2.png) no-repeat center;
      background-size: cover;
    }
  }
  &.pattern-3 {
    &:before {
      background: url(../image/patterns/pattern-3.png) no-repeat 50% 100%;
      background-size: cover;
    }
  }
  &.pattern-4 {
    &:before {
      background: url(../image/patterns/pattern-4.png) no-repeat 50% 100%;
      background-size: cover;
    }
  }
  &.pattern-5 {
    &:before {
      background: url(../image/patterns/pattern-5.png) no-repeat left bottom;
      background-size: contain;
    }
  }
  &.pattern-6 {
    &:before {
      background: url(../image/patterns/pattern-6.png) no-repeat top right;
      background-size: auto;
    }
  }

}